import React from "react";

interface PatientDetails {
  firstName: string;
  lastName: string;
  age: number | null;
  gender: string;
  weight: number | null;
  height: number | null;
}

interface Props {
  data: PatientDetails;
  onUpdate: (data: PatientDetails) => void;
}

export const PatientInfoForm: React.FC<Props> = ({ data, onUpdate }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    onUpdate({ ...data, [name]: value });
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4 text-amber-800">
        Patient Information
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <input
          name="firstName"
          value={data.firstName}
          onChange={handleChange}
          placeholder="First Name"
          className="w-full p-2 border border-amber-300 rounded"
        />
        <input
          name="lastName"
          value={data.lastName}
          onChange={handleChange}
          placeholder="Last Name"
          className="w-full p-2 border border-amber-300 rounded"
        />
        <input
          name="age"
          type="number"
          value={data.age || ""}
          onChange={handleChange}
          placeholder="Age"
          className="w-full p-2 border border-amber-300 rounded"
        />
        <input
          name="gender"
          value={data.gender}
          onChange={handleChange}
          placeholder="Gender"
          className="w-full p-2 border border-amber-300 rounded"
        />
        <input
          name="weight"
          type="number"
          value={data.weight || ""}
          onChange={handleChange}
          placeholder="Weight"
          className="w-full p-2 border border-amber-300 rounded"
        />
        <input
          name="height"
          type="number"
          value={data.height || ""}
          onChange={handleChange}
          placeholder="Height"
          className="w-full p-2 border border-amber-300 rounded"
        />
      </div>
    </div>
  );
};
