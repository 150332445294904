import React from "react";

interface MedicalCondition {
  condition: string;
  icd10: string;
  isChronic: boolean;
  from: string;
  to: string;
}

interface Props {
  history: MedicalCondition[];
  onUpdate: (history: MedicalCondition[]) => void;
}

export const HistoryPane: React.FC<Props> = ({ history, onUpdate }) => {
  const handleAdd = () => {
    onUpdate([
      ...history,
      { condition: "", icd10: "", isChronic: false, from: "", to: "" },
    ]);
  };

  const handleChange = (
    index: number,
    field: keyof MedicalCondition,
    value: string | boolean
  ) => {
    const updatedHistory = [...history];
    updatedHistory[index] = { ...updatedHistory[index], [field]: value };
    onUpdate(updatedHistory);
  };

  const handleRemove = (index: number) => {
    const updatedHistory = history.filter((_, i) => i !== index);
    onUpdate(updatedHistory);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4 text-blue-800">
        Medical History
      </h3>

      {/* Conditionally show table only when history exists */}
      {history.length > 0 && (
        <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
          <thead className="bg-amber-100">
            <tr>
              <th className="border border-gray-300 p-2">Condition</th>
              <th className="border border-gray-300 p-2">ICD-10 Code</th>
              <th className="border border-gray-300 p-2">Is Chronic</th>
              <th className="border border-gray-300 p-2">From</th>
              <th className="border border-gray-300 p-2">To</th>
              <th className="border border-gray-300 p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {history.map((condition, index) => (
              <tr key={index}>
                <td className="border border-gray-300 p-2">
                  <input
                    value={condition.condition}
                    onChange={(e) =>
                      handleChange(index, "condition", e.target.value)
                    }
                    className="p-1 w-full"
                    placeholder="Condition"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    value={condition.icd10}
                    onChange={(e) =>
                      handleChange(index, "icd10", e.target.value)
                    }
                    className="p-1 w-full"
                    placeholder="ICD-10"
                  />
                </td>
                <td className="border border-gray-300 p-2 text-center">
                  <input
                    type="checkbox"
                    checked={condition.isChronic}
                    onChange={(e) =>
                      handleChange(index, "isChronic", e.target.checked)
                    }
                    className="p-1"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    value={condition.from}
                    onChange={(e) =>
                      handleChange(index, "from", e.target.value)
                    }
                    className="p-1 w-full"
                    placeholder="From (date)"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    value={condition.to}
                    onChange={(e) => handleChange(index, "to", e.target.value)}
                    className="p-1 w-full"
                    placeholder="To (date or 'ongoing')"
                  />
                </td>
                <td className="border border-gray-300 p-2 text-center">
                  <button
                    onClick={() => handleRemove(index)}
                    className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <button
        onClick={handleAdd}
        className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
      >
        Add Medical Condition
      </button>
    </div>
  );
};
