
//@ts-ignore
import { LogoutModal } from "@/components/LogoutModal";
import { Home, Calendar, LogOut } from "lucide-react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/context/AuthContext";

export function Sidebar() {
  const { user, logout } = useAuth();
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);

  const handleLogout = () => {
    setLogoutModalOpen(true);
  };

  const handleConfirmLogout = () => {
    logout();
    setLogoutModalOpen(false);
  };

  const handleCloseModal = () => {
    setLogoutModalOpen(false);
  };

  return (
    <div className="w-64 bg-gray-900 text-white p-4">
      <h1 className="text-2xl font-bold mb-6">Vedita AI</h1>
      <nav>
        <ul className="space-y-2">
          <SidebarItem href="/doctor/dashboard" icon={<Home />} text="Home" />
          <SidebarItem href="/doctor/appointments" icon={<Calendar />} text="Patient Visits" />
        </ul>
        <div className="absolute bottom-4 left-4 right-4">
          <Button onClick={handleLogout} className="flex items-center space-x-2 p-2 rounded hover:bg-gray-800">Logout</Button>
        </div>
      </nav>
      
      <LogoutModal
        isOpen={isLogoutModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmLogout}
      />
    </div>
  );
}

function SidebarItem({ href, icon, text }: { href: string; icon: React.ReactNode; text: string }) {
  return (
    <li>
      <Link to={href} className="flex items-center space-x-2 p-2 rounded hover:bg-gray-800">
        {icon}
        <span>{text}</span>
      </Link>
    </li>
  );
}