import React from "react";

interface FamilyHistory {
  relation: string;
  condition: string;
  icd10: string;
}

interface Props {
  familyHistory: FamilyHistory[];
  onUpdate: (familyHistory: FamilyHistory[]) => void;
}

export const FamilyHistoryPane: React.FC<Props> = ({
  familyHistory,
  onUpdate,
}) => {
  const handleAdd = () => {
    onUpdate([...familyHistory, { relation: "", condition: "", icd10: "" }]);
  };

  const handleChange = (
    index: number,
    field: keyof FamilyHistory,
    value: string
  ) => {
    const updatedFamilyHistory = [...familyHistory];
    updatedFamilyHistory[index] = {
      ...updatedFamilyHistory[index],
      [field]: value,
    };
    onUpdate(updatedFamilyHistory);
  };

  const handleRemove = (index: number) => {
    const updatedFamilyHistory = familyHistory.filter((_, i) => i !== index);
    onUpdate(updatedFamilyHistory);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4 text-amber-800">
        Family History
      </h3>

      {/* Conditionally show table only when familyHistory exists */}
      {familyHistory.length > 0 && (
        <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
          <thead className="bg-amber-100">
            <tr>
              <th className="border border-gray-300 p-2">Relation</th>
              <th className="border border-gray-300 p-2">Condition</th>
              <th className="border border-gray-300 p-2">ICD-10 Code</th>
              <th className="border border-gray-300 p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {familyHistory.map((entry, index) => (
              <tr key={index}>
                <td className="border border-gray-300 p-2">
                  <input
                    value={entry.relation}
                    onChange={(e) =>
                      handleChange(index, "relation", e.target.value)
                    }
                    className="p-1 w-full"
                    placeholder="Relation"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    value={entry.condition}
                    onChange={(e) =>
                      handleChange(index, "condition", e.target.value)
                    }
                    className="p-1 w-full"
                    placeholder="Condition"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    value={entry.icd10}
                    onChange={(e) =>
                      handleChange(index, "icd10", e.target.value)
                    }
                    className="p-1 w-full"
                    placeholder="ICD-10 Code"
                  />
                </td>
                <td className="border border-gray-300 p-2 text-center">
                  <button
                    onClick={() => handleRemove(index)}
                    className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <button
        onClick={handleAdd}
        className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
      >
        Add Family History
      </button>
    </div>
  );
};
