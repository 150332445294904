import React from "react";

interface Symptom {
  name: string;
  icd10: string;
  sinceWhen: string;
  isChronic: boolean;
  intensity: string;
  onset: string;
  triggerEvents: string;
  moreDescription: string;
}

interface Props {
  symptoms: Symptom[];
  onUpdate: (symptoms: Symptom[]) => void;
}

export const SymptomPane: React.FC<Props> = ({ symptoms, onUpdate }) => {
  const handleAdd = () => {
    onUpdate([
      ...symptoms,
      {
        name: "",
        icd10: "",
        sinceWhen: "",
        isChronic: false,
        intensity: "",
        onset: "",
        triggerEvents: "",
        moreDescription: "",
      },
    ]);
  };

  const handleChange = (
    index: number,
    field: keyof Symptom,
    value: string | boolean
  ) => {
    const newSymptoms = [...symptoms];
    newSymptoms[index] = { ...newSymptoms[index], [field]: value };
    onUpdate(newSymptoms);
  };

  const handleRemove = (index: number) => {
    const newSymptoms = symptoms.filter((_, i) => i !== index);
    onUpdate(newSymptoms);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4 text-amber-800">Symptoms</h3>

      {/* Conditionally show table only when symptoms exist */}
      {symptoms.length > 0 && (
        <table className="min-w-full table-auto border-collapse border border-gray-300 mb-4">
          <thead className="bg-amber-100">
            <tr>
              <th className="border border-gray-300 p-2">Symptom</th>
              <th className="border border-gray-300 p-2">ICD10</th>
              <th className="border border-gray-300 p-2">Since When</th>
              <th className="border border-gray-300 p-2">Is Chronic</th>
              <th className="border border-gray-300 p-2">Intensity</th>
              <th className="border border-gray-300 p-2">Onset</th>
              <th className="border border-gray-300 p-2">Trigger Events</th>
              <th className="border border-gray-300 p-2">More Description</th>
              <th className="border border-gray-300 p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {symptoms.map((symptom, index) => (
              <tr key={index}>
                <td className="border border-gray-300 p-2">
                  <input
                    value={symptom.name}
                    onChange={(e) =>
                      handleChange(index, "name", e.target.value)
                    }
                    className="p-1 w-full"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    value={symptom.icd10}
                    onChange={(e) =>
                      handleChange(index, "icd10", e.target.value)
                    }
                    className="p-1 w-full"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    value={symptom.sinceWhen}
                    onChange={(e) =>
                      handleChange(index, "sinceWhen", e.target.value)
                    }
                    className="p-1 w-full"
                  />
                </td>
                <td className="border border-gray-300 p-2 text-center">
                  <input
                    type="checkbox"
                    checked={symptom.isChronic}
                    onChange={(e) =>
                      handleChange(index, "isChronic", e.target.checked)
                    }
                    className="p-1"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    value={symptom.intensity}
                    onChange={(e) =>
                      handleChange(index, "intensity", e.target.value)
                    }
                    className="p-1 w-full"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    value={symptom.onset}
                    onChange={(e) =>
                      handleChange(index, "onset", e.target.value)
                    }
                    className="p-1 w-full"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    value={symptom.triggerEvents}
                    onChange={(e) =>
                      handleChange(index, "triggerEvents", e.target.value)
                    }
                    className="p-1 w-full"
                  />
                </td>
                <td className="border border-gray-300 p-2">
                  <input
                    value={symptom.moreDescription}
                    onChange={(e) =>
                      handleChange(index, "moreDescription", e.target.value)
                    }
                    className="p-1 w-full"
                  />
                </td>
                <td className="border border-gray-300 p-2 text-center">
                  <button
                    onClick={() => handleRemove(index)}
                    className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <button
        onClick={handleAdd}
        className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
      >
        Add Symptom
      </button>
    </div>
  );
};
