import { useState, useEffect } from 'react';
import { Appointment } from '@/types/doctor/globals';

const useAppointments = () => {
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Fetch appointments data
    // This is a placeholder. Replace with actual API call.
    const fetchAppointments = async () => {
      // const response = await fetch('/api/appointments');
      // const data = await response.json();
      // setAppointments(data);
    };

    fetchAppointments();
  }, []);

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString();
  };

  const addAppointment = (appointment: Partial<Appointment>) => {
    // Here you would typically send the new appointment to your API
    // For now, we'll just add it to the local state
    const newAppointment: Appointment = {
      _id: Date.now().toString(), // Temporary ID
      joining_date: new Date().toISOString(),
      status: 'Scheduled',
      ...appointment,
    } as Appointment;

    setAppointments([...appointments, newAppointment]);
    setIsModalOpen(false);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return {
    appointments,
    formatDate,
    addAppointment,
    isModalOpen,
    openModal,
    closeModal,
  };
};

export default useAppointments;