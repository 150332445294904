import React, { useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { logo } from './assets';
import withLayout from '@/hoc/withLayout';
import GuestLayout from '@/layouts/GuestLayout';
import useRegister from './useRegister';
import { Link } from 'react-router-dom';

const Register: React.FC = () => {
  const { name, setName, email, setEmail, password, setPassword, errors, handleRegister, loading } = useRegister();
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white dark:bg-gray-900">
      <img src={logo} alt="logo" width={240} height={240} className='mb-10'/>
      <div className="mb-4">
        <Input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={`w-64 ${errors.name ? 'border-red-500' : ''}`}
        />
        {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
      </div>
      <div className="mb-4">
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={`w-64 ${errors.email ? 'border-red-500' : ''}`}
        />
        {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
      </div>
      <div className="mb-4">
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={`w-64 ${errors.password ? 'border-red-500' : ''}`}
        />
        {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
      </div>
      <Button onClick={handleRegister} disabled={loading}>
        {loading ? 'Loading...' : 'Register'}
      </Button>
      <Link to="/login" className="mt-4">Already have an account? <strong>Login</strong></Link>
    </div>
  );
};

export default withLayout(GuestLayout)(Register);
