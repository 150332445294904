import { logo } from "./assets";
import { Link } from "react-router-dom";
import useLandingPage from "./useLandingPage";
import { Button } from "@/components/ui/button";
import GuestLayout from "@/layouts/GuestLayout";
import withLayout from "@/hoc/withLayout";

const LandingPage: React.FC = () => {
    const {

    } = useLandingPage();

    return (
        <div className="flex flex-col items-center justify-center bg-white text-gray-800 text-center p-5 md:p-10 min-h-screen">
            <img
                src={logo}
                height={250}
                width={450}
                alt="Vedita AI Logo"
                className="mb-4 md:mb-8"
            />
            <h3 className="font-bold text-xl md:text-2xl mb-4 md:mb-6">
                Bridging Ancient Wisdom and Modern Technology
            </h3>
            <p className="max-w-2xl leading-relaxed text-base md:text-lg mb-4 md:mb-8">
                Experience the power of Ayurveda reimagined for the modern world. Vedita AI is pioneering
                quantitative analysis in Ayurveda, making timeless wellness principles accessible and
                actionable for both individuals and healthcare professionals. Our AI-driven approach
                brings personalized, Ayurveda-inspired health solutions to your fingertips.
            </p>
            <div className="font-bold mt-4 text-xl md:mt-8 md:text-2xl">
                <Link to="/login" >
                    <Button variant="outline">
                        Login
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default withLayout(GuestLayout)(LandingPage);
