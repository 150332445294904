import React from 'react'
import useAppointments from './useAppointments';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { AppointmentTable } from '@/components/doctor/AppointmentTable';
import AppointmentModal from '@/components/doctor/AppointmentModal';
import withLayout from '@/hoc/withLayout';
import DoctorLayout from '@/layouts/Doctor/DoctorLayout';

const Appointments = () => {
    const { 
        appointments, 
        formatDate, 
        addAppointment, 
        isModalOpen, 
        openModal, 
        closeModal 
      } = useAppointments();
    
      return (
        <div className="p-6">
          <Card>
            <CardHeader className="flex flex-row items-center justify-between">
              <CardTitle className="text-2xl font-bold">Appointments</CardTitle>
              <Button onClick={openModal}>New Appointment</Button>
            </CardHeader>
            <CardContent>
              <AppointmentTable appointments={appointments} formatDate={formatDate} />
            </CardContent>
          </Card>
    
          <AppointmentModal
            isOpen={isModalOpen}
            onClose={closeModal}
            onSave={addAppointment}
          />
        </div>
      );
}

export default withLayout(DoctorLayout)(Appointments)