import { Appointment } from "@/types/doctor/globals";
import { useCallback, useEffect, useState } from "react";

const useDashboard = () => {

    const [appointments, setAppointments] = useState<Appointment[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const formatDate = useCallback((date: Date | string) => {
        const d = new Date(date);
        return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
    }, []);

    const addAppointment = useCallback((newAppointment: Partial<Appointment>) => {
        const appointmentToSave: Appointment = {
            ...newAppointment as Appointment,
            _id: newAppointment._id || Date.now().toString(),
            joining_date: new Date().toISOString(),
            status: 'scheduled',
        };
        setAppointments(prevAppointments => [...prevAppointments, appointmentToSave]);
        setIsModalOpen(false);
    }, []);

    const openModal = useCallback(() => setIsModalOpen(true), []);
    const closeModal = useCallback(() => setIsModalOpen(false), []);

    return {
        appointments,
        formatDate,
        addAppointment,
        isModalOpen,
        openModal,
        closeModal
    };
};

export default useDashboard;