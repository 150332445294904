import React, { useEffect } from 'react'
import { useAuth } from '@/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Sidebar } from './Sidebar';
import { AppBar } from './AppBar';

export const DoctorLayout = ({ children }: React.PropsWithChildren<{}>) => {
    const auth = useAuth();
    const navigate = useNavigate();
    useEffect(() => {   
        if (!auth?.user) {
            navigate("/login", { replace: true });
        }
    }, [auth?.user, navigate]);
    return (
        <div>
            <div className="flex h-screen">
                <Sidebar />
                <div className="flex flex-col flex-1">
                    <AppBar />
                    <main className="flex-1 p-6 overflow-auto">{children}</main>
                </div>
            </div>
        </div>
    )
}

export default DoctorLayout;