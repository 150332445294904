import React from 'react'
import { useNavigate } from 'react-router-dom';
import useDashboard from './useDashboard';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { AppointmentTable } from '@/components/doctor/AppointmentTable';
import AppointmentModal from '@/components/doctor/AppointmentModal';
import withLayout from '@/hoc/withLayout';
import DoctorLayout from '@/layouts/Doctor/DoctorLayout';
import { useAuth } from '@/context/AuthContext';

const Dashboard = () => {
    const navigate = useNavigate();
    const { 
        appointments, 
        formatDate, 
      } = useDashboard();
      
      // Get the 10 most recent appointments
      const recentAppointments = appointments
        .sort((a, b) => new Date(b.visit_date).getTime() - new Date(a.visit_date).getTime())
        .slice(0, 10);
    
      const handleNewCase = () => {
        navigate('/doctor/analysis'); // Adjust this path if needed
      };

      return (
        <div className="flex flex-col space-y-6">
            
          <Card>
            <CardHeader>
              <CardTitle className="text-2xl font-bold">Recent Visits</CardTitle>
            </CardHeader>
            <CardContent>
              <AppointmentTable appointments={recentAppointments} formatDate={formatDate} />
              <div className="mt-6 flex justify-end">
                <Button onClick={handleNewCase}>New Case</Button>
              </div>
            </CardContent>
          </Card>
        </div>
      );
}

export default withLayout(DoctorLayout)(Dashboard);