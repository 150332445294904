import api from "./api";

export class ChatService {
  async processInput(doctorInput: string): Promise<any> {
    try {
      const response = await api.post("/chat/process-input", {
        doctorInput,
      });
      return response.data;
    } catch (error) {
      console.error("Error processing AI interaction:", error);
      throw error;
    }
  }

  async generatePrescription(caseData: any): Promise<any> {
    try {
      const response = await api.post("/chat/generate-prescription", {
        caseData,
      });
      return response.data;
    } catch (error) {
      console.error("Error generating prescription:", error);
      throw error;
    }
  }

  async testOpenAI(message: string): Promise<string> {
    try {
      const response = await api.post("/chat/test-openai", { message });
      return response.data.response;
    } catch (error) {
      console.error("Error testing OpenAI:", error);
      throw error;
    }
  }
}
