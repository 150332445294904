import React, { useState } from 'react';
import { Appointment } from '@/types/doctor/globals';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";

interface AppointmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (appointment: Partial<Appointment>) => void;
}

const AppointmentModal: React.FC<AppointmentModalProps> = ({ isOpen, onClose, onSave }) => {
  const [newAppointment, setNewAppointment] = useState<Partial<Appointment>>({
    assessments: [],
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewAppointment({ ...newAppointment, [e.target.name]: e.target.value });
  };

  const handleAssessmentChange = (value: string) => {
    let updatedAssessments = [...(newAppointment.assessments || [])];
    if (updatedAssessments.includes(value)) {
      updatedAssessments = updatedAssessments.filter(item => item !== value);
    } else {
      updatedAssessments.push(value);
    }
    setNewAppointment({ ...newAppointment, assessments: updatedAssessments });
  };

  const handleSave = () => {
    onSave(newAppointment);
    setNewAppointment({ assessments: [] });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>New Appointment</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="patient_name" className="text-right">
              Patient Name
            </Label>
            <Input
              id="patient_name"
              name="patient_name"
              className="col-span-3"
              onChange={handleInputChange}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="assessments" className="text-right">
              Assessments
            </Label>
            <div className="col-span-3">
              {['prakrati', 'vikrati'].map((assessment) => (
                <Button
                  key={assessment}
                  variant={newAppointment.assessments?.includes(assessment) ? "default" : "outline"}
                  className="mr-2 mb-2"
                  onClick={() => handleAssessmentChange(assessment)}
                >
                  {assessment}
                </Button>
              ))}
              {newAppointment.assessments?.length === 0 && (
                <span className="text-sm text-gray-500 ml-2">None selected</span>
              )}
            </div>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="visit_date" className="text-right">
              Visit Date
            </Label>
            <Input
              id="visit_date"
              name="visit_date"
              type="date"
              className="col-span-3"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <DialogFooter>
          <Button onClick={handleSave}>Save Appointment</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AppointmentModal;