import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { AuthProvider } from "./context/AuthContext";
import { Link, Outlet, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import LandingPage from "./pages/LandingPage/LandingPage";
import Login from "./pages/Login/Login";
import DoctorDashboard from "./pages/Doctor/Dashboard/Dashboard";
import Appointments from "./pages/Doctor/Appointments/Appointments";
import Analysis from "./pages/Doctor/Analysis/Analysis";
import Register from './pages/Register/Register';
function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route index element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/doctor/dashboard" element={<DoctorDashboard />} />
          <Route path="/doctor/appointments" element={<Appointments />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/doctor/dashboard' element={<DoctorDashboard />} />
          <Route path='/doctor/appointments' element={<Appointments />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/doctor/analysis" element={<Analysis />} />
        </Route>
      </Routes>
      <ToastContainer />
    </AuthProvider>
  );
}

export default App;
