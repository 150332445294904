import React from "react";

interface Props {
  diagnosis: string[];
  onUpdate: (diagnosis: string[]) => void;
}

export const DiagnosisPane: React.FC<Props> = ({ diagnosis, onUpdate }) => {
  const handleAdd = () => {
    onUpdate([...diagnosis, ""]);
  };

  const handleChange = (index: number, value: string) => {
    const newDiagnosis = [...diagnosis];
    newDiagnosis[index] = value;
    onUpdate(newDiagnosis);
  };

  const handleRemove = (index: number) => {
    const newDiagnosis = diagnosis.filter((_, i) => i !== index);
    onUpdate(newDiagnosis);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4 text-amber-800">Diagnosis</h3>
      {diagnosis.map((item, index) => (
        <div key={index} className="flex mb-2">
          <input
            value={item}
            onChange={(e) => handleChange(index, e.target.value)}
            className="flex-grow p-2 border border-amber-300 rounded-l"
          />
          <button
            onClick={() => handleRemove(index)}
            className="bg-red-500 text-white px-4 rounded-r hover:bg-red-600"
          >
            Remove
          </button>
        </div>
      ))}
      <button
        onClick={handleAdd}
        className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
      >
        Add Diagnosis
      </button>
    </div>
  );
};
