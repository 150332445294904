import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAnalysis } from "./useAnalysis";
import { AIChatBox } from "@/components/chatbox/AIChatBox";
import { LoadingSpinner } from "@/loader/LoadingSpinner";
import { PatientInfoForm } from "@/components/patient/PatientInfoForm";
import { SymptomPane } from "@/components/patient/SymptomPane";
import { HistoryPane } from "@/components/patient/HistoryPane";
import { FamilyHistoryPane } from "@/components/patient/FamilyHistoryPane";
import { DiagnosisPane } from "@/components/patient/DiagnosisPane";
import { TreatmentPane } from "@/components/patient/TreatmentPane";
import withLayout from "@/hoc/withLayout";
import DoctorLayout from "@/layouts/Doctor/DoctorLayout";
// Accordion Component
const Accordion = ({
  title,
  children,
  isOpenByDefault = false,
}: {
  title: string;
  children: React.ReactNode;
  isOpenByDefault?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  return (
    <div className="border border-brown-200 rounded-lg shadow-lg mb-4">
      <div
        className={`flex justify-between items-center p-4 cursor-pointer transition-all duration-300 ${
          isOpen ? "bg-yellow-200" : "bg-green-100"
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <h2 className="text-lg font-medium text-brown-700">{title}</h2>
        <span
          className={`transform transition-transform duration-300 ${
            isOpen ? "rotate-0" : "rotate-180"
          }`}
        >
          {/* Arrow Icon */}
          <svg
            className="w-6 h-6 text-brown-700"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 15l7-7 7 7"
            ></path>
          </svg>
        </span>
      </div>
      {isOpen && <div className="p-4 bg-brown-50">{children}</div>}
    </div>
  );
};

const Analysis = () => {
  const { patientId } = useParams();
  const {
    caseData,
    updateCaseData,
    chatHistory,
    isLoading,
    handleAIInteraction,
    generatePrescription,
  } = useAnalysis(patientId || "");

  return (
    <div className="flex flex-col md:flex-row w-full h-full p-6">
      {/* AI Chat Box */}
      <div className="w-full md:w-1/3 p-4 border border-amber-300 rounded-xl mx-1">
        <h1 className="text-2xl font-bold mb-6 text-brown-800">
          Vedita your AI Assistant
        </h1>
        <AIChatBox
          onSendMessage={handleAIInteraction}
          chatHistory={chatHistory}
          isLoading={isLoading}
        />
        {isLoading && <LoadingSpinner />}
      </div>

      {/* Analysis Components with Accordions */}
      <div className="w-full md:w-2/3 p-4 border border-amber-300 rounded-xl mx-1">
        <h1 className="text-2xl font-bold mb-6 text-brown-800">
          Case Analysis
        </h1>

        <Accordion title="Patient Information" isOpenByDefault>
          <PatientInfoForm
            data={caseData.patientDetails}
            onUpdate={(details) => updateCaseData({ patientDetails: details })}
          />
        </Accordion>

        <Accordion title="Symptom Analysis">
          <SymptomPane
            symptoms={caseData.symptoms}
            onUpdate={(symptoms) => updateCaseData({ symptoms })}
          />
        </Accordion>

        <Accordion title="Medical History">
          <HistoryPane
            history={caseData.history}
            onUpdate={(history) => updateCaseData({ history })}
          />
        </Accordion>

        <Accordion title="Family History">
          <FamilyHistoryPane
            familyHistory={caseData.familyHistory} // Cast to the correct type
            onUpdate={(familyHistory) => updateCaseData({ familyHistory })}
          />
        </Accordion>

        <Accordion title="Diagnosis">
          <DiagnosisPane
            diagnosis={caseData.diagnosis}
            onUpdate={(diagnosis) => updateCaseData({ diagnosis })}
          />
        </Accordion>

        <Accordion title="Treatment Plan">
          <TreatmentPane
            treatment={caseData.treatment}
            onUpdate={(treatment) => updateCaseData({ treatment })}
          />
        </Accordion>

        <button
          onClick={generatePrescription}
          className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          disabled={isLoading}
        >
          Generate Prescription
        </button>
      </div>
    </div>
  );
};

export default withLayout(DoctorLayout)(Analysis);
