
const useLandingPage = () => {

    const handleClick = () => {
        // Handle click logic here
    };

    return {
        handleClick,
    };
};

export default useLandingPage;