import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import api from '../../services/api';
import axios from 'axios';
import { validateEmail, validatePassword, handleApiErrors } from '../../utils/toolkit';

const useLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ email: '', password: '' });
  const [loading, setLoading] = useState(false);
  const { setAuthInfo } = useAuth();

  const validateInputs = () => {
    const emailValidation = validateEmail(email);
    const passwordValidation = validatePassword(password);

    setErrors({
      email: emailValidation.error ? emailValidation.errorText : '',
      password: passwordValidation.error ? passwordValidation.errorText : '',
    });

    return !emailValidation.error && !passwordValidation.error;
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateInputs()) {
      return;
    }

    setLoading(true);

    try {
      const response = await api.post('/auth/login', { email, password });
      const { token, data } = response.data;
      setAuthInfo(token, data);
      // Redirect or show success message here
    } catch (error) {
      handleApiErrors(error);
      if (axios.isAxiosError(error) && error.response) {
        // Handle specific error messages from the server
        const errorData = error.response.data;
        setErrors({
          email: errorData.email || '',
          password: errorData.password || '',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    errors,
    setErrors,
    handleLogin,
    loading,
  };
};

export default useLogin;