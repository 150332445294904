import React, { useState, KeyboardEvent } from "react";

interface Props {
  onSendMessage: (message: string) => void;
  chatHistory: string[];
  isLoading: boolean;
}

export const AIChatBox: React.FC<Props> = ({
  onSendMessage,
  chatHistory,
  isLoading,
}) => {
  const [message, setMessage] = useState("");

  const handleSend = () => {
    if (message.trim() && !isLoading) {
      onSendMessage(message);
      setMessage("");
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.ctrlKey && e.key === 'Enter') {
      handleSend();
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md mt-6 flex flex-col justify-between h-auto">
     
      <div className=" overflow-y-auto mb-4 p-4 h-max-[80vh]">
        {chatHistory.map((msg, index) => (
          <div key={index} className="mb-2">
            {msg}
          </div>
        ))}
      </div>
      <div className="flex">
        <input
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type your message... (Ctrl+Enter to send)"
          disabled={isLoading}
          className="flex-grow p-2 border border-amber-300 rounded-l"
        />
        <button
          onClick={handleSend}
          disabled={isLoading}
          className="bg-amber-500 text-white px-4 rounded-r hover:bg-amber-600 disabled:bg-gray-300"
        >
          Send
        </button>
      </div>
    </div>
  );
};
