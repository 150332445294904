import { ChatService } from "@/services/chat.service";
import { useState, useCallback } from "react";

interface Symptom {
  name: string;
  icd10: string;
  sinceWhen: string;
  isChronic: boolean;
  intensity: string;
  onset: string;
  triggerEvents: string;
  moreDescription: string;
}

interface MedicalCondition {
  condition: string;
  icd10: string;
  isChronic: boolean;
  from: string;
  to: string;
}

interface FamilyHistory {
  relation: string; // Add this line
  condition: string;
  icd10: string;
}

interface PatientDetails {
  firstName: string;
  lastName: string;
  age: number | null;
  gender: string;
  weight: number | null;
  height: number | null;
}

interface CaseData {
  patientDetails: PatientDetails;
  symptoms: Symptom[];
  history: MedicalCondition[];
  familyHistory: FamilyHistory[];
  diagnosis: string[];
  treatment: string[];
}

interface AIResponse {
  response: string;
  action: string;
  updatedCaseData: CaseData;
}

export const useAnalysis = (patientId: string) => {
  const [caseData, setCaseData] = useState<CaseData>({
    patientDetails: {
      firstName: "",
      lastName: "",
      age: null,
      gender: "",
      weight: null,
      height: null,
    },
    symptoms: [],
    history: [],
    familyHistory: [],
    diagnosis: [],
    treatment: [],
  });

  const [chatHistory, setChatHistory] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const chatService = new ChatService();

  const updateCaseData = useCallback((updates: Partial<CaseData>) => {
    setCaseData((prevData) => ({ ...prevData, ...updates }));
  }, []);

  const fixJsonString = (jsonString: string): string => {
    // Remove any potential newline characters
    let fixed = jsonString.replace(/\n/g, " ");

    // Replace single quotes with double quotes
    fixed = fixed.replace(/'/g, '"');

    // Add quotes to unquoted property names
    fixed = fixed.replace(/(\w+):/g, '"$1":');

    // Replace "True" and "False" with true and false
    fixed = fixed.replace(/"True"/g, "true").replace(/"False"/g, "false");

    // Ensure numbers are not in quotes
    fixed = fixed.replace(/"(\d+(\.\d+)?)"/g, "$1");

    // Remove any trailing commas in objects and arrays
    fixed = fixed.replace(/,\s*([\]}])/g, "$1");

    return fixed;
  };

  const handleAIInteraction = useCallback(
    async (doctorInput: string) => {
      setIsLoading(true);
      try {
        console.log("Sending doctor input:", doctorInput);
        const result = await chatService.processInput(doctorInput);
        console.log("Received result from chatService:", result);

        if (result && result.aiResponse) {
          console.log("Raw aiResponse:", result.aiResponse);
          try {
            const fixedJsonString = fixJsonString(result.aiResponse);
            console.log("Fixed JSON string:", fixedJsonString);
            const aiResponse: AIResponse = JSON.parse(fixedJsonString);
            console.log("Parsed aiResponse:", aiResponse);

            setChatHistory((prev) => [
              ...prev,
              doctorInput,
              aiResponse.response,
            ]);
            console.log("Updated chat history:", [
              ...chatHistory,
              doctorInput,
              aiResponse.response,
            ]);

            updateCaseData(aiResponse.updatedCaseData);
            console.log("Updated case data:", aiResponse.updatedCaseData);
          } catch (parseError) {
            console.error("Error parsing aiResponse:", parseError);
            // Handle parsing error (e.g., show an error message to the user)
          }
        } else {
          console.error("Invalid result structure:", result);
        }
      } catch (error) {
        console.error("Error processing AI interaction:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [chatService, chatHistory, updateCaseData]
  );

  const generatePrescription = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await chatService.generatePrescription(caseData);
      // Handle the prescription result (e.g., update state, display to user)
      console.log("Prescription generated:", result);
    } catch (error) {
      console.error("Error generating prescription:", error);
    } finally {
      setIsLoading(false);
    }
  }, [caseData, chatService]);

  return {
    caseData,
    updateCaseData,
    chatHistory,
    isLoading,
    handleAIInteraction,
    generatePrescription,
  };
};
