import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Appointment } from '@/types/doctor/globals';

interface AppointmentTableProps {
  appointments: Appointment[];
  formatDate: (date: string) => string;
}

export const AppointmentTable: React.FC<AppointmentTableProps> = ({ appointments, formatDate }) => {

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Joining Date</TableHead>
          <TableHead>Patient Name</TableHead>
          <TableHead>Assessments</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Visit Date</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {appointments?.map((appointment) => (
          <TableRow key={appointment._id}>
            <TableCell>{formatDate(appointment.joining_date)}</TableCell>
            <TableCell>{appointment.patient_name}</TableCell>
            <TableCell>{appointment.assessments}</TableCell>
            <TableCell>{appointment.status}</TableCell>
            <TableCell>{formatDate(appointment.visit_date)}</TableCell>
          </TableRow>
        ))}

        {appointments.length === 0 && (
          <TableRow>
            <TableCell colSpan={5} className="text-center py-4">
              <p className="text-gray-500">No records found.</p>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};